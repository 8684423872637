*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: 'Roboto', sans-serif;
}
input[type=number]::-webkit-inner-spin-button{
  -webkit-appearance: none
}
input[type=number]::-webkit-outer-spin-button{
  -webkit-appearance: none
}


/* LOGGIN */
.container-section-login{
  height: 100vh;
  width: 100vw;
  background-image: url(/src/img/imgFondo.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-section-login-info{
  display: grid;
  grid-template-columns: repeat(2 , 1fr);
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
}
.container-section-login-info-form-info{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem;
}
.container-section-login-info-form-info h1{
  font-size: 2rem;
  color: rgba(0, 0, 0, .8);
}
.container-section-login-info-form-info img{
  padding-bottom: 1rem;
}
.container-section-login-info-form{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.container-section-login-info-form input{
  width: 100%;
  background: none;
  border: none;
  border-bottom: solid 1px rgba(0,0,0,.2);
  padding: 10px
}
.container-section-login-info-form input:focus{
  outline: none;
  border-bottom: solid 1px rgba(0,0,0,.2);
}
.contenedor-mejoras-separadas{
  display: flex;
  flex-direction: column;
}
.contenedor-mejoras-separadas select{
  background-color: #f2f2f2;
  color: #333333;
  border: 1px solid #cccccc;
  font-size: 15px;
  padding: 0 8px;
  cursor: pointer;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 0.5rem;
}
.container-section-login-info-form button{
  margin-top: 1rem;
  width: 100%;
  background-color: #8FCD60;
  padding: 8px 0;
  color: #fff;
  border: none;
  border-radius: 15px;
  font-size: 18px;
  cursor: pointer;
  transition: .5s;
}
.container-section-login-info-form button:hover{
  letter-spacing: 2px;
}

/* SECCION FIRMAX */
.contenedor-section-firmax{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.section-firmax{
  background-color: #fff;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.section-firmax-head img{
  width: 400px;
}
.section-firmax-head-title{
  text-align: center;
  color: rgba(0, 0, 0, .6);
  padding-top: 1rem;
}
.section-firmax-main{
  padding-top: 4rem;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 2rem;
}
.btn-delete-bd{
  text-align: center;
  background-color: tomato;
  width: 120px;
  margin: 0 auto;
  margin-top: 1rem;
  border-radius: 15px;
  padding: 3px;
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  cursor: pointer;
}
.section-firmax-main button{
  background-color: #a2f03dc5;
  border: none;
  padding: 1rem;
  width: 300px;
  border-radius: 15px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, .6);
  box-shadow: 0 15px 15px 15px rgba(rgba(162, 240, 61, 0.773));
  cursor: pointer;
  letter-spacing: normal;
  transition: 0.5s ease-in-out;
}
.section-firmax-main button:hover{
  color: rgba(0, 0, 0, .9);
  letter-spacing: 1px;
}
/* SECCION FIRMAX - crear user*/
.section-firmax-form{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  flex-direction: column;
  background-color: #fff;
  padding: 2rem;
  margin-top: 3rem;
  gap: 2rem;
  min-width: 600px;
  max-width: 700px;
  border-radius: 25px;
  text-align: center;
}
.section-firmax-form-container-label-input{
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}
.section-firmax-form label{
  font-size: 18px;
  color: rgba(0, 0, 0, .8);
}
.section-firmax-form input{
  text-align: center;
  background: none;
  border: none;
  border-bottom: solid 1px rgba(0,0,0,.2);
  margin-bottom: 1rem;
}
.section-firmax-form input:focus{
  outline: none;
  border-bottom: solid 1px rgba(0,0,0,.2);
}
.section-firmax-form button{
  background-color: #a2f03dc5;
  border: none;
  padding: 0.3rem;
  margin-top: 0.8rem;
  border-radius: 15px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, .6);
  box-shadow: 0 15px 15px 15px rgba(rgba(162, 240, 61, 0.773));
  cursor: pointer;
  letter-spacing: normal;
  transition: 0.5s ease-in-out;
}
.section-firmax-form button:hover{
  color: rgba(0, 0, 0, .9);
  letter-spacing: 1px;
}
.section-firmax-form p{
  text-align: center;
}
.section-firmax-form-grid-width{
  grid-column: -3/-1;
}
.section-firmax-form-container-back{
  display: flex;
  align-items: center;
  text-align: center;
  color: rgba(0, 0, 0, .7);
  cursor: pointer;
}
.section-firmax-form-container-back:hover{
  color: rgba(0, 0, 0, .9);
}
/* SECCION FIRMAX - mostrar usuarios*/
.section-firmax-inmobiliaria{
  background-color: #fff;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.section-firmax-inmobiliaria i{
  padding-top: 3rem;
  text-align: right;
  cursor: pointer;
}
.section-firmax-inmobiliaria-scroll{
  /*aplicar scroll fixed*/
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  color: rgba(0, 0, 0, .7);
  font-size: 12px;
  gap: 1rem;
}
/* SECCION CREAR USUARIO INMOBILIARIA */
.contenedor-section-crear-user{
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}
.alarma-crear-user{
  text-align: center;
  color: #439108;
  font-size: 28px;
}
.contenedor-section-crear-user-form{
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 300px;
  padding: 1rem;
  border-radius: 15px;
  border: solid 1px #a2f03dc5;
  margin-top: 2rem;
}
.contenedor-section-crear-user-form input{
  background: none;
  border: none;
  border-bottom: solid 1px rgba(0,0,0,.2);
  margin-bottom: 1rem;
}
.contenedor-section-crear-user-form input:focus{
  outline: none;
  border-bottom: solid 1px rgba(0,0,0,.2);
}
.contenedor-section-crear-user-button{
  border: none;
  background-color: #a2f03dc5;
  padding: 10px 0;
  border-radius: 15px;
}
/* ----------------------------------------------------------------------------------- */
.section-fimax-inmobiliaria-scroll-user-contenedor{
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  border-radius: 15px;
  padding: 0 1rem;
  height: 200px;
  width: 300px;
  margin: 0 auto;
  /* display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 1rem; */
}
/*Seccion universal de changePasword */
.section-firmax-form-password{
  display: flex;
  flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  padding: 1rem;
  gap: 1rem;
  border-radius: 25px;
  text-align: center;
  margin-top: 3rem;
  width: auto;
}
.section-firmax-form-password Input{
  text-align: center;
  background: none;
  border: none;
  border-bottom: solid 1px rgba(0,0,0,.2);
  margin-bottom: 1rem;
}
.section-firmax-form-password input:focus{
  outline: none;
  border-bottom: solid 1px rgba(0,0,0,.2);
}
.section-firmax-form-password button{
  background-color: #a2f03dc5;
  border: none;
  padding: 0.3rem;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, .6);
  box-shadow: 0 15px 15px 15px rgba(rgba(162, 240, 61, 0.773));
  cursor: pointer;
  letter-spacing: normal;
  transition: 0.5s ease-in-out;
}
.section-firmax-form-password button:hover{
  color: rgba(0, 0, 0, .9);
  letter-spacing: 1px;
}

/*Seccion de inmo listar*/
/*Hiden para scroll de lista*/
.hidden-scroll{
  overflow: hidden;
}
.section-firmax-inmobiliaria-scroll::-webkit-scrollbar{
  background-color: #fff;
  width: 7px;
  border-radius: 4px;
}
.section-firmax-inmobiliaria-scroll::-webkit-scrollbar-thumb{
  background-color: #a2f03dc5;
  width: 7px;
  border-radius: 4px;
}
.section-firmax-main-lista-users {
  display: flex;
  flex-direction: column;
  align-items: center;
}
/*Boton volver pequeño padding*/
.realitive{
  position: relative;
}
.abosolute{
  position:absolute;
  bottom: 10px;
  left: 0;
}
.padding-top{
  padding-top: 2rem;
}
.section-firmax-main-lista-users button{
  margin: 1rem;
  background-color: #a2f03dc5;
  border: none;
  padding: 0.7rem;
  border-radius: 15px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, .6);
  box-shadow: 0 15px 15px 15px rgba(rgba(162, 240, 61, 0.773));
  cursor: pointer;
  letter-spacing: normal;
  transition: 0.5s ease-in-out;
}
.section-firmax-main-lista-users button:hover{
  color: rgba(0, 0, 0, .9);
  letter-spacing: 1px;
}
.section-firmax-main-lista-users-form input{
  background: none;
  border: none;
  border-bottom: solid 1px rgba(0,0,0,.2);
  margin-left: 1rem;
}
.section-firmax-main-lista-users-form input:focus{
  outline: none;
  border-bottom: solid 1px rgba(0,0,0,.2);
}
.section-firmax-main-lista-users-form button{
  background-color: #a2f03dc5;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  font-size: 15px;
  font-weight: 400;
  color: rgba(0, 0, 0, .6);
  box-shadow: 0 15px 15px 15px rgba(rgba(162, 240, 61, 0.773));
  cursor: pointer;
}
/*Section CALCULADORA*/
.container-firmax{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgba(0, 0, 0, .7);
  padding: 1rem;
  width: 100%;
}
.responsive-footer{
  height: 100vh;
  position: relative;
}
.container-firmax p{
  padding-top: 2rem;
  color: hsl(0, 76%, 61%);
  font-size: 18px;
  text-align: center;
}
.container-firmax-logo{
  width: 300px;
}
.container-firmax h1{
  padding-top: 2rem;
  font-size: 28px;
}
.container-firmax-form{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.container-firmax-form-flex{
  display: flex;
  gap: 0.3rem;
  padding-bottom: 1rem;
}
.container-firmax-form-flex h1{
  font-size: 26px;
}
.container-firmax-form-flex-gap{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 3rem;
  padding-top: 1rem;
}
.container-firmax-form-info{
  background-color: #fff;
  border-radius: 15px;
  border: solid 2px #85ce26c5;
  box-shadow: 0 1px 20px 1px rgba(0, 0, 0, .2);
}
.container-firmax-form-flex-relative{
  position: relative;
}
.container-firmax-form-flex-left{
  position: absolute;
  top: 0;
  cursor: pointer;
  
}
.container-firmax-form-flex-right{
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.container-firmax-form-flex-gap section{
  display: flex;
  flex-direction: column;
}
.container-firmax-form-flex-gap label{
  font-size: 22px;
  text-align: center;
}
.container-firmax-form-flex-gap input{
  margin-top: 2rem;
  background: none;
  border: none;
  border-bottom: solid 2px #85ce26c5;
  border-top: solid 2px #85ce26c5;
  width: 60%;
  text-align: center;
  font-size: 26px;
}
.container-firmax-form-flex-gap input:focus{
  outline: none;
  border-bottom: solid 1px rgba(0,0,0,.2);
}
.container-firmax-form-flex-color{
  color: #85ce26c5;
}
.container-firmax-subTitle{
  padding-top: 2rem;
  font-size: 22px;
  color: rgba(0, 0, 0, .8);
}
.container-firmax-opts{
  display: flex;
  gap: 2rem;
  padding-top: 2rem;
  text-align: center;
  font-size: 14px;
}
.container-firmax-opts-check input{
  margin-top: 1rem;
  width: 20px;
  height: 20px;
}
.container-firmax-section-fija{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 25px;
  border: solid 2px #85ce26c5;
  margin-top: 1rem;
  box-shadow: 0 5px 15px 5px rgba(0, 0, 0, .2);
  padding: 2rem;
  width: 375px;
  height: 500px;
}
.container-firmax-section-fija-gap{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.container-firmax-section-fija-colorOs {
  color: rgba(0, 0, 0, .8);
  font-size: 28px;
  font-weight: 700;
}
.container-firmax-section-fija-info-flex{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem;
}
.container-firmax-section-fija-input{
  background: none;
  border: none;
  border-bottom: solid 2px #85ce26c5;
  border-top: solid 2px #85ce26c5;
  text-align: center; 
  font-weight: 100;
  font-size: 1rem;
  width: 50%;
}
.input-range-width{
  width: 100%;
  margin-top: 0.3rem;
}
.container-firmax-section-fija-flex{
  display: flex;
  gap: 1rem;
}
.container-firmax-section-fija-flex h2{
  padding-top: 1rem;
}
.container-firmax-section-fija-button{
  background: #a2f03dc5;
  border: none;
  padding: 0.7rem;
  border-radius: 15px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, .6);
  box-shadow: 0 15px 15px 15px rgba(rgba(162, 240, 61, 0.773));
  cursor: pointer;
  letter-spacing: normal;
  transition: 0.5s ease-in-out;
  text-decoration: none;
  text-align: center;
  width: 240px;
  display: flex;
  justify-content: center;
  margin: 1rem;
}
.container-firmax-section-fija-button:hover{
  color: rgba(0, 0, 0, .9);
  letter-spacing: 1px;
}
/* Dentro de calculadora cuestionarios*/
.container-firmax-section-cuestionario{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 0 0 25px 25px;
  border: solid 2px #85ce26c5;
  margin: 0 0 2rem 0;
  box-shadow: 0 10px 15px 5px rgba(0, 0, 0, .2);
  padding: 2rem; 
}
.contenedor-solicitantes{
  display: flex;
  margin-top: 4rem;
}
.contenedor-solicitantes span{
  position: absolute;
  top: 0;
  right: 5px;
  font-weight: 700;
  color: rgba(0, 0, 0, .7);
  cursor: pointer;
}
.contenedor-solicitantes span:hover{
  font-weight: 700;
  color: tomato;
}
.contenedor-solicitantes h5{
  border: solid 2px #85ce26c5;
  padding: 1rem 2rem;
  z-index: 3;
}
.container-firmax-section-cuestionario-contenedor-form{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  width: 700px;
}
.container-firmax-section-cuestionario-contenedor-form input{
  width: 100%;
  background: none;
  border: none;
  border-bottom: solid 1px rgba(0,0,0,.2);
  padding: 10px;
}
.container-firmax-section-cuestionario-contenedor-form input:focus{
  outline: none;
  border-bottom: solid 1px rgba(0,0,0,.2);
}
.container-firmax-div-flex{
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.container-firmax-div-flex-row{
  display: flex;
  justify-content: center;
  gap: 3rem;
}
.container-firmax-div-flex-row div{
  display: flex;
  gap: 0.3rem;
}
.container-firmax-div-flex label{
  color: rgba(0,0,0,.9);
  text-align: start;
}
.container-firmax-div-flex-label-center label{
  text-align: center;
}
.container-firmax-div-flex select{
  border: solid 1px rgba(0,0,0,.2);
}
.container-firmax-div-flex textarea{
  height: 200px;
  border: solid 1px rgba(0,0,0,.2);
}
.container-firmax-div-flex textarea:focus{
  outline: none;
  border-bottom: solid 1px rgba(0,0,0,.2);
}
.margin-buttom-cuest{
  margin-top: 2rem;
  width: 100px;
}
.container-firmax-section-cuestionario-contenedor-form-flex{
  display: flex;
  justify-content: center;
  gap: 3rem;
  padding-top: 2rem;
}
.container-firmax-section-cuestionario-contenedor-form-flex input , h4{
  margin-top: 10px;
  background: none;
  border: none;
  border: solid 4px #85ce26c5;
  padding: 25px;
  border-radius: 15px;
  font-size: 22px;
  text-align: center;
}
.colorAdv{
  margin-top: 10px;
  background: none;
  border: none;
  border: solid 4px #85ce26c5;
  padding: 25px;
  border-radius: 15px;
  font-size: 22px;
  text-align: center;
}
.colorAdvRed{
  margin-top: 10px;
  background: none;
  border: none;
  border: solid 4px rgb(238, 117, 96);
  padding: 25px;
  border-radius: 15px;
  font-size: 22px;
  text-align: center;
}
.honorarios-gestion-mas{
  position: absolute;
  right: 10px;
  top: 20px;
  font-size: 2.5rem;
  font-weight: 700;
  cursor: pointer;
}
.honorarios-gestion-menos{
  position: absolute;
  left: 15px;
  top: 15px;
  font-size: 2.8rem;
  font-weight: 700;
  cursor: pointer;
  z-index: 1;
}
.container-firmax-section-cuestionario-contenedor-form-flex input:focus{ 
  border: solid 4px #85ce26c5;
  outline: none;
}
.container-firmax-section-cuestionario-contenedor-tres-border{
  border: solid 1px #85ce26c5;
  margin-top: 2rem;
  padding: 1px;
  font-size: 22px;
}
.grid-dos-dos{
  display: grid;
  grid-template-columns: repeat(2,1fr);
}
.container-firmax-section-cuestionario-contenedor-form-flex select{
  margin-top: 1rem;
  padding: 22px;
  border-radius: 15px;
  border: solid 4px #85ce26c5;
}
.no-target{
  position: fixed;
  top: -50rem;
}
.valorAproxSegundaGarantia-contenedor-tres-porcentaje{
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.valorAproxSegundaGarantia-contenedor-tres-porcentaje h1{
  padding-bottom: 2rem;
  font-size: 24px;
}
.container-firmax-div-flex-column{
  display: flex;
  flex-direction: column;
}
.container-firmax-div-flex-column input , select{
  background: none;
  border: none;
  border: solid 2px #85ce26c5;
  border-radius: 15px;
  font-size: 22px;
  text-align: center;
  width: 200px;
  height: 40px;
}
.container-firmax-div-flex-column input:focus{ 
  border: solid 2px #85ce26c5;
  outline: none;
}
.container-firmax-div-flex-uno-row{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
 }
 .container-firmax-div-flex-uno-column{
   display: flex;
   gap: 0.3rem;
 }
/*Calculadora-footer*/
.footer{
  text-align: center;
  padding: 2rem;
  background-color: #fff;
  width: 100%;
  position: absolute;
  bottom: 0;
}
.bx-check-circle{
  color: #7ec423c5;
  font-size: 5rem;
  font-weight: 100;
}
/*------------ CEAR PARAMETROS CALCULADORA*/
.width-buttons-opt-parametros-center{
  display: flex;
  align-items: center;
}
.width-buttons-opt-parametros{
  width: 200px;
}
.container-flex-opt-escenarios{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
  box-shadow: 3px 5px 15px rgba(0, 0, 0, .2);
  border-radius: 10px;
}
/* style={{position:"absolute" ,left:"235px", top:"-19px" ,padding:"0" , height:"30px" , width:"60px" , borderRadius:"0" , border:"none" , fontSize:"17px", fontWeight:"700"}} */

.container-flex-opt-escenarios input{
  width: 340px;
}
.contenedor-calculadora-parametros-firmax-input{
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(2,1fr);
  flex-direction: column;
  gap: 30px;
  padding: 1.5rem;
  border-radius: 15px;
  border: solid 1px #85ce26c5;
  background: #fff;
}
.grid-entero{
  grid-column: 1/3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.grid-witht{
  width: 300px;
  background: #fff;
  border: none;
  border: solid 1px rgba(0,0,0,.2);
  padding: 5px;
  border-radius: 10px;
}
.button-grid{
  grid-column: -3/-1;
  width: auto;
}
/* top:"60px", left:"195px", fontSize:"22px" */
.symbol{
  position: absolute;
  top: 65px;
  left: 195px;
  font-size: 18px;
}
.junto{
  left: 190px;
}
.container-flex-opt-escenarios-color-title{
  color: rgba(0, 0, 0, .7);
}
.contenedor-calculadora-parametros-firmax-input button{
  display: flex;
  align-items: center;
  justify-self: center;
}
.container-flex-opt-escenarios-column{
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
}
.container-flex-opt-escenarios-column-center{
  text-align: center;
  font-size: 22px;
  font-weight: 600;
}
.container-flex-opt-escenarios-column label{
  font-size: 18px;
  color: rgba(0, 0, 0, .8);
}
.calculadora-parametros-firmax-input{
  background: #fff;
  border: none;
  border: solid 1px rgba(0,0,0,.2);
  padding: 5px;
  border-radius: 10px;
  width: 100%;
}
.calculadora-parametros-firmax-input:focus{
  outline: none;
  border: solid 1px rgba(0,0,0,.2);
}
.contenedor-calculadora-parametros-firmax-select{
  display: flex;
  align-items: center;
}
.contenedor-calculadora-parametros-firmax-select select{
  width: 100px;
  height: 20px;
}
.contenedor-calculadora-parametros-firmax-select button{
  padding: 15px;
  height: 20px;
}
.logo-calculadora{
  width: 380px;
}
.contenedor-calculadora-parametros-firmax{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}
/* CREATOR CALCULADORA */
.contenedor-calculadora-creacion-firmax{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contenedor-calculadora-creacion-firmax-form{
  border: solid 1px black;
  margin-top: 3rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  background: #fff;
  border-radius: 25px;
  border: solid 2px #85ce26c5;
  margin: 4rem 0;
  box-shadow: 0 5px 15px 5px rgba(0, 0, 0, .2);
}
.btn-crear-calculadora{
  border: solid 1px black;
  padding: 1rem;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 25px;
  border: solid 2px #85ce26c5;
  box-shadow: 0 5px 15px 5px rgba(0, 0, 0, .2);
  cursor: pointer;
  transform: scale(1);
  transition: 0.3s;
}
.btn-crear-calculadora:hover{
  transform: scale(1.1);
}
.grid{
  display: grid;
  grid-template-columns: repeat(2,1fr);
}
.contenedor-calculadora-creacion-firmax-form-opts-buttons{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  margin: 0 auto;
  gap: 1rem;
}
.cont-opt-next{
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
}
.cont-opt-next button{
  grid-column: 1/3;
}
.cont-btns-finally{
  display: flex;
  flex-direction: column;
}
.form-indi{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.font{
  font-weight: 700;
}
.contenedor-calculadora-creacion-firmax-form label{
  font-size: 20px;
  color: rgba(0, 0, 0, .8);
  background-color: #fff;
} 
.contenedor-calculadora-creacion-firmax-form input{
  background: none;
  border: none;
  border-bottom: solid 2px rgba(0, 0, 0, .2);
  font-size: 18px;
  color: rgba(0, 0, 0, .8);
}
.contenedor-calculadora-creacion-firmax-form input:focus{
  outline: none;
  border-bottom: solid 2px rgba(0, 0, 0, .2);
}
.contenedor-calculadora-creacion-firmax-form-button{
  background-color: #a2f03dc5;
  border: none;
  padding: 0.7rem;
  border-radius: 15px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, .8);
  box-shadow: 0 15px 15px 15px rgba(rgba(162, 240, 61, 0.773));
  cursor: pointer;
  letter-spacing: normal;
  transition: 0.5s ease-in-out;
  text-decoration: none;
  text-align: center;
}
.contenedor-calculadora-creacion-firmax-form-button:hover{
  letter-spacing: 1px;
}
.contenedor-calculadora-creacion-firmax-form-row{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background-color: #fff;
}
.contenedor-calculadora-creacion-firmax-form-grid{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  gap: 2rem;
  background-color: #fff;
}
/* ASIGNAR CALCULADORA*/
.contenedor-calculadora-creacion-firmax-section{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.contenedor-calculadora-creacion-firmax-section-select{
  padding-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.form-grid-inmo{
  display: grid;
  grid-template-columns: repeat(2,1fr);
}
.section-firmax-inmobiliaria-arrow{
  cursor: pointer;
}
/*   VER CALCULADORAS   */
.container-firmax-verCalculadoras{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container-firmax-verCalculadoras-button-calc{
  background-color: #a2f03dc5;
  border: none;
  margin-top: 3rem;
  padding: 0.7rem;
  border-radius: 15px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, .8);
  box-shadow: 0 15px 15px 15px rgba(rgba(162, 240, 61, 0.773));
  cursor: pointer;
  letter-spacing: normal;
  transition: 0.5s ease-in-out;
  text-decoration: none;
}
.container-firmax-verCalculadoras-contenedor-buttons{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  margin-top: 2rem;
}
.container-firmax-verCalculadoras-contenedor-button{
  background-color: #a2f03dc5;
  border: none;
  margin: 1rem;
  padding: 0.7rem;
  border-radius: 15px;
  font-size: 18px;
  font-weight: 400;
  color: rgba(0, 0, 0, .8);
  box-shadow: 0 15px 15px 15px rgba(rgba(162, 240, 61, 0.773));
  cursor: pointer;
  letter-spacing: normal;
  transition: 0.5s ease-in-out;
  text-decoration: none;
  width: 150px;
  text-align: center;
}
.cuadro-informativo{
  position: relative;
}
.cuadro-informativo-absolute-one{
  position: absolute;
  right: 0px;
  top: -90px;
  border: #8FCD60 solid 4px;
  padding: 10px;
  background-color: #fff;
  font-size: 18px;
  color: rgba(0, 0, 0, .8);
  border-radius: 15px;
}
.cuadro-informativo-absolute-two{
  position: absolute;
  right: 0px;
  top: -110px;
  border: #8FCD60 solid 4px;
  padding: 10px;
  background-color: #fff;
  font-size: 18px;
  color: rgba(0, 0, 0, .8);
}
.one-absolute{
  position: absolute;
  right: 0;
  bottom: 10px;
  cursor: pointer;
  font-size: 24px;
  color: #439108;
}
/* intereses de calculadora */
.container-firmax-form-title-intereses{
  display: flex;
  color: rgba(0, 0, 0, .8);
  gap: 7px;
}
.container-firmax-form-buttons-container{
  padding-top: 0rem;
}
.container-firmax-cuotas{
  display: flex;
  gap: 10px;
}
.relative{
  position: relative;
}
.two-absolute{
  position: absolute;
  right: -30px;
  top: 30px;
  font-size: 24px;
  cursor: pointer;
  color: #439108;
}
.two-absolute-date{
  position: absolute;
  right: -30px;
  top: 28px;
  cursor: pointer;
  font-size: 24px;
  color: #439108;
}
::-webkit-calendar-picker-indicator{
  font-size: 1.5rem;
}
.cuadro-informativo-absolute-one-left{
  position: absolute;
  right: 0px;
  top: -50px;
  border: #8FCD60 solid 4px;
  padding: 10px;
  background-color: #fff;
  font-size: 18px;
  color: rgba(0, 0, 0, .8);
  border-radius: 15px;
}
.tres-absolute{
  cursor: pointer;
  position: absolute;
  top: -1px;
  right: -10px;
  font-size: 24px;
  color: #439108;
}
.tres-absolute-bis{
  cursor: pointer;
  position: absolute;
  top: -1px;
  right: 260px;
  font-size: 24px;
  color: #439108;
}
.cuadro-informativo-absolute-tres{
  position: absolute;
  right: -310px;
  top: -100px;
  border: #8FCD60 solid 4px;
  padding: 10px;
  background-color: #fff;
  font-size: 18px;
  color: rgba(0, 0, 0, .8);
  border-radius: 15px;
}
.cuadro-informativo-absolute-tres-bis{
  position: absolute;
  right: 25px;
  top: -160px;
  border: #8FCD60 solid 4px;
  padding: 10px;
  background-color: #fff;
  font-size: 18px;
  color: rgba(0, 0, 0, .8);
  border-radius: 15px;
}
.container-firmax-section-fija-info-flex-left{
  position: absolute;
  top: 95px;
  left: -18px;
}
.container-firmax-section-fija-info-flex-right{
  position: absolute;
  top: 95px;
  right: -18px;
}
.title-calculadora{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;
  color: rgba(0, 0, 0, .8);
  gap: 0.5rem;
  font-size: 13px;
}
.title-calculadora-sub-principal{
  font-size: 26px;
  color: #439108;
}
.title-calculadora-sub{
  font-size: 22px;
  color: #262626;
}
.espaciado{
  padding-top: 2rem;
}
.container-firmax-section-confirmar-cancelar{
  display: flex;
}
.leyenda-grid-alarm{
  grid-column: 1/3;
  color: #439108;
  font-size: 28px;
  cursor: pointer;
}
.absolute-leyenda{
  position: absolute;
  border: solid 2px #439108;
  padding: 10px;
  border-radius: 10px;
  top: -65px;
  left: 120px;
  background: #fff;
  cursor: pointer;
}
.escenarios-container{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  align-items: center;
  justify-content: center;
  gap: 3rem;
  background-color: #fff;
}
.escenarios-container-individual{
  box-shadow: 2px 2px 15px 2px rgba(0, 0, 0, .3);
  padding: 1rem;
  text-align: center;
  width: 600px;
  background: #fff
}
.bg{
  background-color: #fff;
}
.tabla-contratos{
  width: 600px;
  display: flex;
  gap: 2rem;
  justify-content: space-between;
  align-items: center;
  text-align: end;
  margin-bottom: 0.5rem;
  border: solid 1px rgba(67, 145, 8 , .2);
  padding-left: 20px;
}
.ancho-index{
  width: 20px;
  text-align: start;
  font-weight: 700;
}
.ancho-nombre{
  width: 500px;
  text-align: start;
}
.bx-left-arrow-alt{
  font-family: 'Roboto', sans-serif;
}
.tabla-contratos-mod-eli{
  display: flex;
  align-items: center;
}
.bx-calendar-edit{
  background: #ffbb00;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  font-size: 24px;
  padding: 0 5px;
}
.sombra{
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);
  z-index: 1;
}
.form-edit {
  position: absolute;
  z-index: 2;
  background-color: #fff;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 400px;
  border-radius: 25px;
  padding: 1rem;
}
.form-crear{
  position: absolute;
  z-index: 3;
  background-color: #fff;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 430px;
  border-radius: 25px;
  padding: 1rem;
}
.form-edit label{
  font-weight: 700;
}
.form-edit label span{
  font-weight: 400;
}
.contenedor-buttons-calcu{
  display: flex;
  gap: 1rem;
  padding-top: 3rem;
}
.width-button-calcu{
  width: 130px;
}
.contenedor-modificcar-calcu{
  padding: 3rem 0;
  display: flex;
  gap: 1rem;
}
.escenarios-container-individual div section input{
  width: 100%;
  color: red;
}
.escenarios-container-individual-sction-pena{
  display: flex;
  flex-direction: column;
  padding: 3rem;
  border-radius: 25px;
}
.escenarios-container-individual-sction-pena input{
  width: 100%;
  background: none;
  border: none;
  border-bottom: solid 1px rgba(0,0,0,.2);
}
.container-buttons-save-cancel{
  display: flex;
  justify-content: center;
  align-items: center;
}
.ancho{
  width: 200px;
  font-size: 14px;
}
.input-select-width select{
  width: 100%;
  height: auto;
  color: #3c3c3c;
  text-align: start;
  font-size: 14px;
}
.alto{
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contenedor-buttons-setCal{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 5rem 0;
}
.anchoButonCal{
  width: 200px;
}
.select-size{
  font-size: 18px;
  color: #262626;
}
.select-size:focus{ 
  border: solid 2px #85ce26c5;
  outline: none;
}
.column{
  flex-direction: column;
}
.contenedor-simulacion{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: rgba(0, 0, 0, .6);
  border: solid 2px rgba(238, 104, 80, 0.315);
  padding: 1rem;
  border-radius: 10px;
}
.contenedor-simulacion button{
  margin: 0 auto;
}
.aprobada-contenedor{
  border: solid 2px #a4f13e8e;
}
.contenedor-simulacion-res{
  text-align: center;
  border: solid 5px rgba(238, 103, 80, 0.726);
  border-radius: 25px;
  padding: 2rem;
  margin: 2rem 0;
  font-weight: 700;
}
.aprobada-res{
  border: solid 5px #85ce26c5;
}
.contenedor-simulacion-res-img{
  width: 200px;
  height: 150px;
}
.contenedor-simulacion-leyenda{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1rem;
}
.contenedor-simulacion-mejoras{
  padding: 2rem 0;
}
.contenedor-simulacion-mejoras-opts{
  display: flex;
  align-items: center;
  gap: 1rem;
}
.leyenda-crear-calc{
  position: absolute;
  bottom: 102px;
  right: 190px;
  font-size: 24px;
}
.section-firmax-main-crear-mejoras{
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  gap: 2rem;
}
.section-agregar-mejora-guardar{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
}
.section-agregar-mejora-guardar label{
  padding-top: 1rem;
}
.section-agregar-mejora-guardar input{
  width: 100%;
  background: none;
  border: none;
  border-bottom: solid 1px rgba(0,0,0,.2);
}
.section-agregar-mejora-guardar input:focus{
  outline: none;
  border-bottom: solid 1px rgba(0,0,0,.2);
}
.section-agregar-mejora-guardar button{
  margin-top: 1rem;
}
.input-edit-mejora{
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, .8);
  align-items: center;
  justify-content: center;
}
.input-edit-mejora-campos{
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 2rem;
  border-radius: 15px;
  gap: 1rem;
  width: 400px;
}
.contenedor-simulacion-mejoras{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.contenedor-simulacion-mejoras-contenedor-info{
  display: flex;
  gap: 0.5rem;
}
.contfinal{
  padding-left: 0.3srem;
}
.contenedor-simulacion-detalles{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: solid 1px #8FCD60;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 2rem;
 
}
.contenedor-simulacion-detalles-dos{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: solid 1px #8FCD60;
  padding: 10px;
  border-radius: 15px;
  margin-bottom: 2rem;
  margin-top: 1rem;
}
.contenedor-simulacion-detalles-info{
  display: flex;
  gap: 0.8rem;
}
.contenedor-simulacion-detalles-info-dos{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  gap: 0.3rem;
}
.contenedor-simulacion-detalles-info-texto{
  border: solid 4px #8FCD60;
  padding: 10px 1rem;
  text-align: center;
  border-radius: 25px;
  font-size: 15px;
  color: rgba(0, 0, 0, .7);
}
.contenedor-simulacion-detalles-datos{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: solid 4px #8FCD60;
  margin-top: 1rem;
  padding: 10px 1rem;
  border-radius: 25px;
  color: rgba(0, 0, 0, .6);
}
.contenedor-simulacion-detalles-datos-row{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3rem;
}
.form-contratos{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contenedor-simulacion-contratos{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  border: solid 1px #8FCD60;
  padding: 1rem;
}
.contenedor-simulacion-contratos-datos{
  display: flex;
  flex-direction: column;
  width: 500px;
}
.contenedor-simulacion-contratos h3{
  margin-bottom: 1rem;
}
.contenedor-simulacion-contratos input{
  background: none;
  border: none;
  border-bottom: solid 1px rgba(0,0,0,.2);
  margin-bottom: 1rem;
}
.contenedor-simulacion-contratos input:focus{
  outline: none;
  border-bottom: solid 1px rgba(0,0,0,.2);
}
.contenedor-simulacion-contratos button{
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-button-opts{
  display: flex;
}
/* FORMATO CONTRATO A CARGO DEL VENDEDOR */
.contratos{
  width: 90vw;
  padding: 1rem;
}
.contrato-cargo-vendedor-num-solicitud{
  text-align: left;
}
.contrato-cargo-vendedor-dia{
  text-align: right;
}
.contrato-cargo-vendedor-firmas{
  display: grid;
  grid-template-columns: repeat(2,1fr);
}
.contrato-cargo-vendedor-firmas-individual{
  border: solid 1px #8FCD60;
  height: 100px;
  padding-left: 10px;
}
.grid-tres{
  display: grid;
  grid-template-columns: repeat(3,1fr);
}

/* PANTALLA DE RECUPERAR SIMULACION */
.rec-simulacion{
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.rec-simulacion-dos{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.search-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 3px 10px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 2rem;
}
.search-container h1 {
  color: #333;
  font-size: 20px;
}
.search-container input[type="tel"] {
  width: 100%;
  padding: 3px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  margin: 8px 0;
}
.search-container-buttom {
  background-color: #a2f03dc5;
  border: none;
  padding: 3px 0;
  border-radius: 15px;
  font-size: 18px;
  color: rgba(0, 0, 0, .8);
  box-shadow: 0 15px 15px 15px rgba(rgba(162, 240, 61, 0.773));
  cursor: pointer;
  text-decoration: none;
  width: 150px;
  margin: 1rem 0;
}
.search-container button:hover {
  background-color: #a2f03d9a;
}

/* SECCION SIMULACIONES ADMIN FIRMAX */
.section-firmax-simulaciones{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  height: 100vh;
}
.section-firmax-simulaciones img{
  width: 350px;
}
.margin-top{
  margin-top: 3rem;
  color: #0000009c;
}
.text-left{
  text-align: left;
}
.text-left span{
  font-weight: 700;
  color: #000000c9;
}
.pad-bot{
  margin-bottom: 1rem;
}
.btns-recalculo{
  background-color: #a2f03dc5;
  border: none;
  padding: 5px 0;
  border-radius: 10px;
  font-size: 18px;
  color: rgba(0, 0, 0, .8);
  box-shadow: 0 15px 15px 15px rgba(rgba(162, 240, 61, 0.773));
  cursor: pointer;
  text-decoration: none;
  width: 250px;
}
.btns-recalculo:hover {
  background-color: #94d63c9a;
}

.contenesor-usuarios{
  margin-top: 3rem;
  max-height: 300px;
  overflow-x: auto;
  overflow-y: auto;
  max-width: 750px;
}
.max-width{
  max-width: 1200px;
  max-height: 600px;
}
.agregar{
  letter-spacing: normal;
  transition: 0.3s;
  cursor: pointer;
}
.agregar:hover{
  letter-spacing: 1px;
}
.agregar span{
  color: #439108;
  font-weight: 700;
  font-size: 22px;
}

.main-medico {
  border-collapse: collapse;
  max-width: 1100px;
  min-width: 900px;
  max-height: 600px;
  color: rgba(0, 0, 0, .6);
  margin-top: 0.5rem;
  overflow-x: auto;
}
.bx-trash, .bx-edit-alt{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 22px;
  cursor: pointer;
}
.main-medico td, .main-medico th{
  border: 1px solid #ddd;
  padding: 3px 10px;
}
.main-medico thead tr th{
  border: 1px solid #fff;
}
.main-medico tr:nth-child(even){background-color: #f2f2f2;}

.main-medico tr:hover {background-color: #ddd;}

.main-medico th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #94d63c9a;
}
.border-solid{
  border-bottom: 1px solid rgba(0, 0, 0, .4);
}
.border{
  border-bottom: 1px solid rgba(0, 0, 0, .2);
}

.contenedor-info-agrencia{
  width: 600px;
  border: 1px solid #439108;
  box-shadow: 0 5px 5px rgba(0, 0, 0, .3);
  display: flex;
  flex-direction: column;
  color: rgba(0, 0, 0, .7);
  padding: 1rem;
  border-radius: 5px;
  margin-top: 4rem;
}
.contenedor-info-agrencia h2{
  text-align: center;
  margin-bottom: 1rem;
}
.info-agrencia{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  align-items: flex-start;
  gap: 0.5rem;
}
.info-agrencia p span{
  font-weight: 700;
}
/* section de new user inmo admin */
.sombra-crear-new{
  background-color: rgba(0, 0, 0, .8);
  height: 100vh;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}
.new-user{
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  width: 300px;
}
.input-label {
  font-size: 14px;
  color: #333;
  display: block;
  margin-bottom: 4px;
}

.input-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.edit-button {
  background-color: #94d63c9a;
  color: rgba(0, 0, 0, .6);
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  margin-top: 1rem;
}
.edit-button:hover {
  background-color: #94d63c9a;
}
.contenedor-filtro-busqueda{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.busqueda-fecha{
  display: flex;
  align-items: center;
  gap: 1rem;
}
.busqueda-fecha input{
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease-in-out;
}
.busqueda-fecha input::-webkit-calendar-picker-indicator {
  filter: invert(50%);
}
.btn-buscar{
  padding: 8px 12px;
  font-size: 14px;
  border: none;
  background-color: #94d63c9a;
  color: rgba(0, 0, 0, .6);
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s ease-in-out;
  height: 2.3rem;
}
.btn-buscar:hover {
  background-color: #93d63cd5;
}
/* media de celu */
@media (max-width: 480px) {
  .section-firmax-simulaciones{
    height: auto;
  }
  .contenedor-simulacion-detalles-info-dos{
    grid-template-columns: repeat(2,1fr);
  }
  .contenedor-filtro-busqueda{
    flex-direction: column;
  }
  .busqueda-fecha{
    margin-bottom: 0.5rem;
  }
  .search-container {
    width: 100%; 
  }
  .contenedor-simulacion-detalles-info{
    display: flex;
    gap: 0.1rem;
  }
  .contenedor-simulacion-detalles-info-texto{
    border: solid 4px #8FCD60;
    padding: 10px;
    text-align: center;
    border-radius: 25px;
    font-size: 15px;
  }
  .contenedor-simulacion{
    width: 100%;
    margin: 0 auto;
  }
  .form-contratos{
    width: 100%;
    margin: 0 auto;
  }
  .contenedor-simulacion-contratos-datos{
    width: 80%;
  }
  .contenesor-usuarios{
    overflow-x: auto;
    max-width: 90%;
    margin: 0 auto;
    padding-top: 3rem;
  }
  .max-width{
    max-width: 300px;
    max-height: 400px;
    overflow-x: auto;
    overflow-y: auto;
  }
  .contenedor-info-agrencia{
    width: 90%;
    margin: 0 auto;
  }
  .info-agrencia{
    grid-template-columns: repeat(1,1fr);
  }
  .select-hipoteca-porcetaje{
    right: 20px;
    top: -400px;
  }
  .tres-absolute{
    cursor: pointer;
    position: absolute;
    top: -1px;
    right: 330px;
    font-size: 24px;
    color: #439108;
  }
  .tres-absolute-bis{
    cursor: pointer;
    position: absolute;
    top: -1px;
    right: 295px;
    font-size: 24px;
    color: #439108;
  }
  .grid{
    grid-template-columns: repeat(1,1fr);
  }
  .contenedor-calculadora-creacion-firmax-form-opts-buttons{
    grid-template-columns: repeat(1,1fr);
  }
  .contenedor-section-firmax{
    height: auto;
  }
  .main-firmax-form-alto{
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .leyenda-crear-calc{
    position: absolute;
    bottom: 104px;
    right: 20px;
    font-size: 20px;
  }
  .contenedor-solicitantes span{
    position: absolute;
    top: 0;
    right: 5px;
    font-weight: 700;
    color: rgba(0, 0, 0, .7);
    cursor: pointer;
  }
  .contenedor-solicitantes h5{ 
    padding:15px 5px;
  }
  .logo-crear-user{
    width: 250px;
  }
  .column{
    display: flex;
  }
  .logo-calculadora{
    max-width: 250px;
  }
  .section-firmax-main{
    display: grid;
    grid-template-columns: repeat(1 , 1fr);
    padding: 2rem 0;
  }
  .responsive-footer{
    height: normal;
  }
  .container-section-login{
    height: 100vh;
  }
  .footer{
    text-align: center;
    padding-top: 2rem;
    background-color: #fff;
    width: 100%;
    position: relative;
  }
  .container-section-login-info{
    grid-template-columns: repeat(1 , 1fr);
    width: 90%;
    overflow: hidden;
    gap: 0;
  }
  .container-section-login-info-form-info h1{
    font-size: 1.5rem;
    color: rgba(0, 0, 0, .7);
  }
  .container-section-login-info-form-info img{
    padding-bottom: 1rem;
    width: 60%;
  }
  .container-section-login-info-form input{
    max-width: 300px;
  }
  /*-------------------------------*/
  .section-firmax-head img{
    width: 100%;
  }
  .section-firmax-main button{
    width: 250px;
    font-size: 16px; 
    padding: 1rem;
  }
  /*----------------------------------*/
  .section-firmax-inmobiliaria img{
    width: 90%;
  }
  .section-firmax-inmobiliaria-scroll{
    /*aplicar scroll fixed*/
    grid-template-columns: repeat(1,1fr);
  }
  .section-firmax-main-lista-users-hidden{
    display: flex;
    flex-direction: column;
  }
  .section-firmax-main-lista-users-form{
    display: flex;
    flex-direction: column;
  }
  .section-firmax-main-lista-users-form input{
    margin-top: 2rem;
  }
  .container-flex-opt-escenarios{
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    padding-bottom: 1.3rem;
  }
  /* section calculadora*/
  .container-firmax-form-flex-gap{
    gap: 1rem;
  }
  .contenedor-calculadora-parametros-firmax{
    height: auto;
  }
  .contenedor-calculadora-parametros-firmax img{
    padding-bottom: 8rem;
  }
  .contenedor-calculadora-parametros-firmax-input{
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
  }
  .logo-firmax{
    width: 300px;
  }
  /*section calculadora cuestionario*/
  .container-firmax-section-cuestionario{
    padding: 0.2rem;
  }
  .container-firmax-section-cuestionario-contenedor-form{
    width: 90%;
    gap: 3rem;
  }
  .container-firmax-section-cuestionario-contenedor-form-flex{
    flex-direction: column;
  }
  .grid-dos-dos{
    display: grid;
    grid-template-columns: repeat(1,1fr);
  }
  .container-firmax-section-cuestionario-contenedor-form-flex-relative{
    position: relative;
    background-color: red;
  }
  .container-firmax-div-flex-row{
    flex-direction: column;
  }
  .container-firmax-div-flex-uno-row{
   display: flex;
   align-items: center;
   justify-content: center;
   gap: 3rem;
  }
  .container-firmax-div-flex-uno-column{
    display: flex;
    gap: 0.3rem;
  }
  /* crear parametros opts */
  .width-buttons-opt-parametros-center{
    flex-direction: column;
    align-items: center;
  }
  /* crear calculadora */
  .contenedor-calculadora-creacion-firmax-form{
    padding: 2rem;
    width: 100%;
  }
  .contenedor-calculadora-creacion-firmax-form-grid{
    grid-template-columns: repeat(1,1fr);
    gap: 2rem;
  }
  /* ASIGNAR CALUCLADORA */
  .contenedor-calculadora-creacion-firmax-section {
    width: 100%;
  }
  .contenedor-calculadora-creacion-firmax-section img{
    width: 300px;
  }
  .contenedor-calculadora-creacion-firmax-section-select{
    padding-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
  }
  .form-grid-inmo{
    display: flex;
    width: 100%;
  }
  .section-firmax-inmobiliaria-arrow{
    padding-bottom: 3rem;
  }
  /* intereses de calculadora */
  .container-firmax-form-title-intereses{
  display: flex;
  flex-direction: column;
  align-items: center;
  }
  .container-firmax-form-buttons-container{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cuadro-informativo-absolute-tres-bis{
    right: 25px;
    top: 0px;  
  }
  .cuadro-informativo-absolute-tres{
    right: 25px;
    top: -100px;  
  }
  .two-absolute{
    right: -20px;
    top: 30px;
  }
  .two-absolute-date{
    position: absolute;
    right: -20px;
    top: 32px;
    cursor: pointer;
    font-size: 24px;
    color: #439108;
  }
  .container-firmax-section-confirmar-cancelar{
    flex-direction: column;
  }
  .symbol{
    top: 65px;
    left: 230px;
  }
  .junto{
    left: 216px;
  }
  .tabla-contratos{
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 0;
  }
  .ancho-index{
    width: 10%;
    text-align: start;
  }
  .ancho-nombre{
    width: 80%;
    text-align: start;
  }
  .contenedor-buttons-calcu{
    flex-direction: column;
  }
  .width-button-calcu{
    width: 250px;
  }
  .contenedor-modificcar-calcu{
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .contenedor-modificcar-calcu button{
    margin-bottom: 3rem;
  }
  .escenarios-container{
    grid-template-columns: repeat(1,1fr);
    width: 100%;
    padding: 1rem;
  }
  .escenarios-container-individual{
    padding: 0rem;
    width: 100%;
  }
  .escenarios-container-individual section input{
    width: 50px;
  }
  .escenarios-container-individual-sction-pena{
    padding: 3rem 0.3rem;
  }
  .form-edit{
    width: 380px;
  }
  .form-crear{
    width: 390px;
  }
  .container-buttons-save-cancel{
    flex-direction: column;
  }
  .section-firmax-form{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 1rem;
    margin-top: 3rem;
    gap: 2rem;
    width: 200px;
    border-radius: 25px;
    text-align: center;
  }
  .alto{
    flex-direction: column;
  }
  .container-button-opts{
    flex-direction: column;
  }
  .contenedor-simulacion-mejoras-opts{
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

/* media de tablet */
@media (min-width: 750px) and (max-width: 1024px) {
  .container-section-login-info{
    grid-template-columns: repeat(1 , 1fr);
  }
  .container-section-login-info-form input{
    max-width: 300px;
    font-size: 18px;
  }
  /*---------------------------------------*/
  .section-firmax-main button{
    width: 330px;
    font-size: 24px;
  }
  /*--------cuestionario--------------*/
  .container-firmax-section-cuestionario{
    padding: 1rem;
  }
  .two-absolute{
    right: -15px;
    top: 30px;
  }
  .two-absolute-date{
    position: absolute;
    right: -15px;
    top: 32px;
    cursor: pointer;
    font-size: 24px;
    color: #439108;
  }
  .contenedor-calculadora-parametros-firmax-input{
    padding: 0px;
  }
  .contenedor-calculadora-parametros-firmax{
    padding: 0;
  }
  .symbol{
    top: 61px;
    left: 205px;
  }
  .junto{
    left: 190px;
  }
}

/* LOADING EFECTO */
.contenedor-ring{
  width: 100%;
  height: 100vh;
  background:#262626;
  position: fixed;
  top: 0;
  right: 0;
}
.ring
{
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  width:150px;
  height:150px;
  background:transparent;
  border:3px solid #3c3c3c;
  border-radius:50%;
  text-align:center;
  line-height:150px;
  font-family:sans-serif;
  font-size:20px;
  color:#8FCD60;
  letter-spacing:4px;
  text-transform:uppercase;
  text-shadow:0 0 10px #8FCD60;
  box-shadow:0 0 20px rgba(0,0,0,.5);
}
.ring:before
{
  content:'';
  position:absolute;
  top:-3px;
  left:-3px;
  width:100%;
  height:100%;
  border:3px solid transparent;
  border-top:3px solid #8FCD60;
  border-right:3px solid #8FCD60;
  border-radius:50%;
  animation:animateC 2s linear infinite;
}
h6
{
  display:block;
  position:absolute;
  top:calc(50% - 2px);
  left:50%;
  width:50%;
  height:4px;
  background:transparent;
  transform-origin:left;
  animation:animate 2s linear infinite;
}
h6:before
{
  content:'';
  position:absolute;
  width:16px;
  height:16px;
  border-radius:50%;
  background:#8FCD60;
  top:-6px;
  right:-8px;
  box-shadow:0 0 20px #8FCD60;
}
@keyframes animateC
{
  0%
  {
    transform:rotate(0deg);
  }
  100%
  {
    transform:rotate(360deg);
  }
}
@keyframes animate
{
  0%
  {
    transform:rotate(45deg);
  }
  100%
  {
    transform:rotate(405deg);
  }
}